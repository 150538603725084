import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonIcon from '@mui/icons-material/Person';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import '../styles/Refer.css';
import axios from 'axios';

const Refer = () => {
  const [mobile, setMobile] = useState('');
  const inputRef = useRef(null);
  const [referralEarning, setReferralEarning] = useState('');
  const [directReferral, setDirectReferral] = useState('');

  const handleCopy = () => {
    if (inputRef.current) {
      const valueToCopy = `https://99ludoclub.com/Login?id=${inputRef.current.value}`;
      navigator.clipboard.writeText(valueToCopy)
        .then(() => {
          toast.success('Copied to clipboard!', { position: 'top-center' });
        })
        .catch(err => {
          console.error('Failed to copy:', err);
          toast.error('Failed to copy!', { position: 'top-center' });
        });
    }
  };

  useEffect(() => {
    const userMobileNumber = getCookie('user');
    if (userMobileNumber) {
      setMobile(userMobileNumber);
      fetchReferralEarning(userMobileNumber);
      fetchDirectReferral(userMobileNumber);
    }
  }, []);

  const fetchReferralEarning = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api5/referralearning', {
        mobileNumber
      });
      const { rearning } = response.data;
      setReferralEarning(rearning);
    } catch (error) {
      console.error('Failed to fetch referral earnings:', error.message);
      setReferralEarning('0');
    }
  };

  const fetchDirectReferral = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api5/directreferral', {
        mobileNumber
      });
      const { userd } = response.data;
      setDirectReferral(userd);
    } catch (error) {
      console.error('Failed to fetch direct referrals:', error.message);
      setDirectReferral('0');
    }
  };

  const message = encodeURIComponent(`Hi Friends, Play 99LudoClub and earn 10000 daily. Download Indian App 99LudoClub.

    बम्मफर धमाका ऑफर दोस्तों आ गया है भारत का पहला ऐसा गेमिंग प्लेटफॉर्म जहा आपको win पर रैफर कमीशन 5% दिया जाएगा अगर आपका दोस्त आपके रेफर से गेम खेलता तो आपको 3% commission दिया जाएगा और तुरंत अपने Bank या UPI में ट्रांसफर कर सकते हो।
    
    100% भरोसेमंद प्लेटफार्म । 24 hours support https://99ludoclub.com/Login?id=${mobile}`);

    const getCookie = (name) => {
      const nameEQ = name + '=';
      const cookiesArray = document.cookie.split(';');
      for (let i = 0; i < cookiesArray.length; i++) {
        let c = cookiesArray[i].trim();
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    };

  return (
    <div className="refer-container">
      <div className="refer-code-section">
        <h3>Referral Code</h3>
        <div className="referral-code">
          <input type="text" value={mobile} readOnly ref={inputRef} />
          <button className="themecolour2" onClick={handleCopy}>Copy</button>
          <ToastContainer />
        </div>
        <p>OR</p>
        <div className="social-icons">
          <a
            className="whatsapp-button"
            href={`whatsapp://send?text=${message}`}
          >
           <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="icons" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"></path></svg> &nbsp; WhatsApp
          </a>
        </div>
      </div>
      <div className="referral-earnings-section">
        <h4>Your Referral Earnings</h4>
        <div className="earnings-details">
          <div className="earning-item">
            <PersonIcon className="icon" style={{ color: '#8e44ad', fontSize: '2rem' }} />
            <div className="earning-info">
              <p className="label">Referred Players</p>
              <p className="value">{directReferral}</p>
            </div>
          </div>
          <div className="earning-item">
            <MoneyIcon className="icon" style={{ color: '#27ae60', fontSize: '2rem' }} />
            <div className="earning-info">
              <p className="label">Referral Earnings</p>
              <p className="value">₹{referralEarning}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refer;
