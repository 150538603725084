import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { FaBars } from 'react-icons/fa';
import { GiWallet } from "react-icons/gi";
import '../styles/Header.css';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Header = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [mainBalance, setMainBalance] = useState('0');
  const [upgradeBalance, setUpgradeBalance] = useState('0');
  const [mobileNumber, setMobileNumber] = useState('0');
  const [content, setContent] = useState('');
  const [logo,setLogo] = useState('');

  useEffect(() => {
    const userMobileNumber = getCookie('user');
    if (userMobileNumber) {
      fetchWalletBalance(userMobileNumber);
      setMobileNumber(userMobileNumber);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleAddCashClick = () => {
    navigate('/addcash');
  };
  const handleWithdrawalClick = () => {
    navigate('/withdrawal');
  };
  const handleWalletClick = () => {
    navigate('/Wallet');
  };
  const logoUrl = `${logo}favicon.png`;
  const fetchContent = async (pageName) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api5/pagecontent', {
        pageName  
      });

      if (response.data && response.data.Content) {
        const fetchedContent = response.data.Content;
        setContent(fetchedContent);
      } else {
        console.log('No content found in the response');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('Content not found');
      } else {
        console.log('Failed to call API:', error.message);
      }
    }
  };
  const fetchContent2 = async (pageName) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api5/pagecontent', {
        pageName  
      });

      if (response.data && response.data.Content) {
        const fetchedContent = response.data.Content;
        setLogo(fetchedContent);
      } else {
        console.log('No content found in the response');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('Content not found');
      } else {
        console.log('Failed to call API:', error.message);
      }
    }
  };

  const fetchWalletBalance = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api5/walletbalance', {
        mobileNumber
      });

      const { mainbal, upgradebal } = response.data;
      setMainBalance(mainbal);
      setUpgradeBalance(upgradebal);
    } catch (error) {
      console.error('Failed to fetch wallet balance:', error.message);
      // Handle error, set balances to default or show an error message
      setUpgradeBalance('0');
    }
  };

  useEffect(() => {
    if (!mobileNumber) {
      return;
    }
    fetchContent2('Logo');
    fetchContent('HeaderPage');
    // Fetch balance immediately on component mount
    fetchWalletBalance(mobileNumber);

    // Set up interval to fetch balance every 7 seconds
    const interval = setInterval(() => {
      fetchWalletBalance(mobileNumber);
    }, 10000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [mobileNumber]);
  const getCookie = (name) => {
    const nameEQ = name + '=';
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let c = cookiesArray[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  return (
    <>
      <AppBar position="fixed" className='theme-colour'>
        <div class="header_top_message themecolour2"><span class="">{content}</span></div>
        <Toolbar>
          <div className="menu-icon" onClick={toggleSidebar}>
            <FaBars />
          </div>
          {isOpen && (
            <div className="overlay" onClick={toggleSidebar} />
          )}
          <Sidebar ref={sidebarRef} isOpen={isOpen} toggleSidebar={toggleSidebar} />
          <Typography variant="subtitle1" style={{ flexGrow: '1', lineHeight:0 }}>
            <img className='logo' src={logoUrl} alt="84LudoHub" style={{ height: '40',marginLeft: '10px' }} />
          </Typography>
          {/* <button onClick={handleAddCashClick} className='depositButton'>
          <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
            <Typography variant="subtitle2">
              Coins:{upgradeBalance}
            </Typography>
          </Box>
          </button> */}
          <button onClick={handleWalletClick} className='withdrawButton' style={{backgroundColor: 'transparent',border: 'white 1px solid'}}>
          <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
            <Typography variant="subtitle2">
            <GiWallet />    Wallet:{mainBalance}
            </Typography>
          </Box>
          </button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
