import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/AddCash.css';
import axios from 'axios';

const AddCash = () => {
    const [mobile, setMobile] = useState('');
    const [amount, setAmount] = useState(100);
    const inputRef = useRef(null);
    const [transaction, setTransaction] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [upiImage, setUpiImage] = useState('');
    const [upiCode, setUpiCode] = useState('');
    const [loading, setLoading] = useState(false);  // Add a loading state

    const handleCopy = () => {
        if (inputRef.current) {
            const valueToCopy = `${inputRef.current.value}`; 
            navigator.clipboard.writeText(valueToCopy)
                .then(() => {
                    toast.success('Copied to clipboard!', { position: 'top-center' });
                })
                .catch(err => {
                    toast.error('Failed to copy text', { position: 'top-center' });
                });
        }
    };

    useEffect(() => {
        const userMobileNumber = getCookie('user');
        if (userMobileNumber) {
            setMobile(userMobileNumber);
            fetchAccountDetails(userMobileNumber);  // Call the API after setting mobile
        }
    }, []);  // Dependency array ensures this runs only once

    const fetchAccountDetails = async (mobileNumber) => {
        setLoading(true);  // Start loading
        try {
            const response = await axios.post('https://api.successfamily.live/api5/accountdetails', { mobileNumber });
            const { accountname, accountno, ifsccode, upiimage, upiid } = response.data;

            setAccountName(accountname || '');
            setAccountNumber(accountno || '');
            setIfscCode(ifsccode || '');
            setUpiImage(upiimage || '');
            setUpiCode(upiid || '');

            setLoading(false);  // Stop loading
        } catch (error) {
            console.error('Failed to fetch account details:', error.message);
            toast.error('Error fetching account details', { position: 'top-center' });
            setLoading(false);  // Stop loading in case of error
        }
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleTransactionChange = (e) => {
        setTransaction(e.target.value);
    };

    const handlePresetClick = (value) => {
        setAmount(value);
    };

    const handleSubmit = async () => {
        if (amount < 100) {
            toast.warning('Add amount must be at least 100', { position: 'top-center' });
            return;
        }
        if (!transaction) {
            toast.warning('Please Enter Transaction Id', { position: 'top-center' });
            return;
        }
        try {
            const response = await axios.post('https://api.successfamily.live/api5/addcash', {
                mobile,
                amount,
                transaction,
            });
            const { message, status } = response.data;
            if (status === 1) {
                toast.success('Add Cash Submit Successfully', { position: 'top-center' });
            } else {
                toast.warning(message, { position: 'top-center' });
            }
        } catch (error) {
            toast.error('Add Cash failed: ' + (error.response?.data?.message || error.message), { position: 'top-center' });
        }
    };

    const getCookie = (name) => {
        const nameEQ = name + '=';
        const cookiesArray = document.cookie.split(';');
        for (let i = 0; i < cookiesArray.length; i++) {
            let c = cookiesArray[i].trim();
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    };

    return (
        <>
            <div className="Addcashheading">
                <h1>Add Money</h1>
            </div>
            <div className="add-cash-container">
                <h3>Choose Amount To Add</h3>

                <div className="AccountDetails">
                    <h2>Account details</h2>
                    {loading ? (
                        <p>Loading account details...</p>
                    ) : (
                        <>
                            <label>Account Name: {accountName || 'N/A'}</label>
                            <label>Account No: {accountNumber || 'N/A'}</label>
                            <label>Ifsc Code: {ifscCode || 'N/A'}</label>
                            <img
                                src={`https://admin.99ludoclub.com/admin/Uploadupi/${upiImage}`}
                                width="200px"
                                alt="UPI Image"
                                style={{ margin: 'auto', marginTop: '10px' }}
                            />
                            <div className="refer-code">
                                <input type="text" value={upiCode} readOnly ref={inputRef} />
                                <button onClick={handleCopy}>Copy</button>
                            </div>
                        </>
                    )}
                </div>

                <div className="input-section">
                    <label htmlFor="amount">Enter Amount</label>
                    <div className="amount-input">
                        <span>💰</span>
                        <input
                            type="number"
                            id="amount"
                            value={amount}
                            onChange={handleAmountChange}
                        />
                    </div>
                </div>

                <div className="input-section">
                    <label htmlFor="transaction">Enter Transaction Id</label>
                    <div className="amount-input">
                        <input
                            id="transaction"
                            onChange={handleTransactionChange}
                        />
                    </div>
                </div>

                <div className="preset-buttons">
                    <button onClick={() => handlePresetClick(100)}>💰100</button>
                    <button onClick={() => handlePresetClick(250)}>💰250</button>
                    <button onClick={() => handlePresetClick(500)}>💰500</button>
                    <button onClick={() => handlePresetClick(2000)}>💰2000</button>
                </div>

                <button className="next-button" onClick={handleSubmit}>Submit</button>
                <ToastContainer />
            </div>
        </>
    );
};

export default AddCash;
