import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const getCookie = (name) => {
    const nameEQ = name + '=';
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i];
      while (c.charAt(0) === ' ') c = c.substring(1);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      const user = getCookie('user');
      const sessionToken = getCookie('sessionToken');

      if (user && sessionToken) {
        const currentTime = new Date().getTime();
        const sessionExpiry = parseInt(getCookie('sessionExpiry'), 10);

        if (currentTime < sessionExpiry) { // Check if the session token is still within its expiry time
          try {
            // Server-side validation of the session token
            const response = await axios.post('https://api.successfamily.live/api5/validate-session', { sessionToken });

            if (response.data.status === 1) {
              // Session is valid
              setIsAuthenticated(true);
            } else {
              // Session is invalid (due to login on another device or other reasons)
              console.log('Session invalidated or user logged in on another device.');
              clearSession();
              setIsAuthenticated(false);
            }
          } catch (error) {
            console.error('Session validation failed:', error);
            setIsAuthenticated(false);
          }
        } else {
          // Session expired
          clearSession();
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }

      setLoading(false); // Set loading to false after checking
    };

    checkAuthentication();

    // Set up an interval to regularly check session validity (e.g., every 30 seconds)
    const intervalId = setInterval(() => {
      checkAuthentication();
    }, 30000); // Check every 30 seconds 

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Function to clear session from cookies
  const clearSession = () => {
    document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    document.cookie = 'sessionToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    document.cookie = 'sessionExpiry=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  };

  if (loading) {
    // Render a loading state while checking authentication
    return <div>Loading...</div>;
  }

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
