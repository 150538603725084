import React, { useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/AddCash.css';
import axios from 'axios';

const Withdrawal = () => {
    const [mobile, setMobile] = useState('');
    const [amount, setAmount] = useState('');
    const [wager, setWager] = useState(0);

    useEffect(() => {
        const userMobileNumber = getCookie('user');
        if (userMobileNumber) {
            setMobile(userMobileNumber);
            fetchDetails(userMobileNumber);
        }
      }, []);

      const minimumWithdrawl = 100;

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };
    const handleSubmit = async () => {
      if (amount < minimumWithdrawl) {
        // Show a warning toast if the amount is less than minimumWithdrawl
        toast.warning(`Withdrawal amount must be at least ${minimumWithdrawl}`, { position: 'top-center' });
        return; // Exit the function early
      }
      if (wager > 0) {
        // Show a warning toast if wager is not greater than 0
        toast.warning('Wager must be 0 to proceed with withdrawal', { position: 'top-center' });
        return; // Exit the function early
      }
        try {
          const response = await axios.post('https://api.successfamily.live/api5/withdrawalcash', {
            mobile,
            amount,
          });
          const { message , status } = response.data;
          console.log(response.data);
          if (status === 1) {
          toast.success('Withdrawal Submit Successfully', { position: 'top-center' });
          } else {
            toast.warning(message, { position: 'top-center' });
          }
          
        } catch (error) {
          toast.warning('Withdrawal failed:' + (error.response?.data?.message || error.message), { position: 'top-center' });
        }
      };

      const fetchDetails = async (mobileNumber) => {
        try {
          const response = await axios.post('https://api.successfamily.live/api5/remaingwager', {
            mobileNumber
          });
          const wager = response.data.wager;
          setWager(wager);
        } catch (error) {
          if (error.response && error.response.status === 404) {
          } else {
            console.log('Failed to call API:', error.message);
          }
        }
      };
      const getCookie = (name) => {
        const nameEQ = name + '=';
        const cookiesArray = document.cookie.split(';');
        for (let i = 0; i < cookiesArray.length; i++) {
          let c = cookiesArray[i].trim();
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      };

    return ( 
        <>
        <div className="Addcashheading">
        <h1>Withdrawal Money</h1>
        </div>
        <div className="add-cash-container">
            <p className='notedetails'>Note : Transaction charges will be as follows:-

Bank Transfer and UPI withdraw charges from ₹ 50 to ₹ 1000 will be ₹ 7 + 1.75 % Service Charges, from ₹ 1001 to ₹ 10000 will be ₹ 14 + 1.75 % Service Charges and above ₹ 10000 will be ₹ 18 + 1.75 % Service Charges
After approval from admin, sometimes withdrawl payout will be reflect in bank account with in 3 working days, if withdrawl will be SUCCESS. Incase of FALURE your withdrawl amount will credited into your chips.</p>
            <div className="input-section">
              <label>Current Wager :- {wager}</label><br></br>
                <label htmlFor="amount">Enter Amount (Minimum {minimumWithdrawl})</label>
                <div className="amount-input">
                    <span>💰</span>
                    <input 
                        type="number" 
                        id="amount" 
                        value={amount}
                        onChange={handleAmountChange} 
                    />
                </div>
            </div>
            <button className="next-button" onClick={handleSubmit}>Withdrawal</button>
            <ToastContainer />
        </div>
        </>
    );
};

export default Withdrawal;
