import React, { useState, useEffect } from 'react';
import '../styles/TransactionHistory.css'; // Make sure to create this file for styling

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [mobile, setMobile] = useState("");
  const [transactionType, setTransactionType] = useState('All');
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const transactionsPerPage = 10; // Adjust the number of transactions per page as needed

  useEffect(() => {
    const userMobileNumber = getCookie("user");
    if (userMobileNumber) {
      setMobile(userMobileNumber);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!mobile) return; // Don't fetch if mobile number is not available
      setIsLoading(true); // Start loading
      setTransactions([]);
      try {
        let apiEndpoint;
        let formatData;

        switch (transactionType) {
          case 'Game':
            apiEndpoint = 'https://api.successfamily.live/api5/gametransaction';
            formatData = (data) => data.map(item => ({
              battleID: item.battleID,
              opponent_name: item.opponent_name,
              result: item.result,
              amount: item.amount,
              winner_id: item.winner_id,
              room_id: item.roomcode,
              date: new Date(item.Time),
              error: item.error,
            }));
            break;
          case 'Referral':
            apiEndpoint = 'https://api.successfamily.live/api5/referraltransaction';
            formatData = (data) => data.map(item => ({
              type: item.Type,
              desc: item.Desc,
              mode: item.Mode,
              amount: item.amount,
              date: new Date(item.Time),
              error: item.error,
            }));
            break;
          case 'All':
          default:
            apiEndpoint = 'https://api.successfamily.live/api5/transaction';
            formatData = (data) => data.map(item => ({
              orderid: item.orderid,
              type: item.Type,
              status: item.status,
              desc: item.desc,
              amount: item.amount,
              desc2: item.naranation,
              date: new Date(item.Time),
              error: item.error,
            }));
        }

        const response = await fetch(apiEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mobileNumber: mobile }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const formattedData = formatData(data);

        setTransactions(formattedData);
      } catch (error) {
        setTransactions([]);
        console.error('Error fetching data:', error);
      } finally {
         setIsLoading(false); // Stop loading
      }
    };

    fetchData();
  }, [mobile, transactionType]);

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC'
    });
  };

  const handleButtonClick = (type) => {
    setTransactionType(type);
    setCurrentPage(1); // Reset to first page when changing type
  };
  const getCookie = (name) => {
    const nameEQ = name + '=';
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let c = cookiesArray[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  return (
    <div className="transaction-history">
      <h2 className="themecolour2">History</h2>
      <p style={{ color: '#fff' }}>You can see your Transaction, Gaming, and Referral History here.</p>
      <div className="tabs">
        <button
          className={transactionType === 'All' ? 'active themecolour2' : 'themecolour2'}
          onClick={() => handleButtonClick('All')}
        >
          All
        </button>
        <button
          className={transactionType === 'Game' ? 'active themecolour2' : 'themecolour2'}
          onClick={() => handleButtonClick('Game')}
        >
          Game
        </button>
        <button
          className={transactionType === 'Referral' ? 'active themecolour2' : 'themecolour2'}
          onClick={() => handleButtonClick('Referral')}
        >
          Referral
        </button>
      </div>

      {/* Display loading indicator */}
      {isLoading ? (
        <div className="loading-spinner"></div> // Updated loading spinner
      ) : (
        <>
          {/* Conditional Rendering Based on Transaction Type */}
          {transactionType === 'All' && (
            <div className="transactions">
              {currentTransactions.length === 0 ? (
                <div className="no-transactions">
                  <img src={require(`../assets/not-found.gif`)} width="120px" height="120px" alt="No Transactions" />
                  <h3>No Transactions Available</h3>
                  <p>You have no History yet.</p>
                </div>
              ) : (
                currentTransactions.map((transaction, index) => (
                  <div className="transaction" key={index}>
                    <div className="transaction-details">
                      <div className="transaction-date">
                        <p>{transaction.desc}</p>
                        <p style={{ fontSize: '12px' }}>Order ID: {transaction.orderid}</p>
                        <p>Status: {transaction.status === '0' ? 'PENDING' : transaction.status === '1' ? 'PAID' : transaction.status === '2' ? 'FAILED' : 'UNKNOWN'}</p>
                        <p style={{ color: 'red' }}>{transaction.desc2}</p>
                      </div>
                      <div className="transaction-amount">
                        <p className={transaction.type === "Credit" ? "Credit" : "Debit"}>
                          {transaction.type === "Credit" ? '(+)' : '(-)'}
                          <>
                            <img
                              src={require(`../assets/rcoin.png`)}
                              width="24px"
                              height="24px"
                              alt="Winning Amount"
                              style={{ marginRight: '4px' }}
                            />
                            {`${transaction.amount}`}
                          </>
                        </p>
                        <p>{formatDate(transaction.date)}</p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}

          {transactionType === 'Game' && (
            <div className="transactions">
              {currentTransactions.length === 0 ? (
                <div className="no-transactions">
                  <img src={require(`../assets/not-found.gif`)} width="120px" height="120px" alt="No Transactions" />
                  <h3>No Transactions Available</h3>
                  <p>You have no Game History yet.</p>
                </div>
              ) : (
                currentTransactions.map((transaction, index) => (
                  <div className="transaction" key={index}>
                    <div className="transaction-details">
                      <div className="transaction-date">
                        <p style={{ fontSize: '14px' }}>Status: {transaction.result}</p>
                        <p style={{ fontSize: '14px' }}>Player Name: {transaction.opponent_name}</p>
                        <p style={{ fontSize: '12px' }}>BattleID: {transaction.battleID}</p>
                      </div>
                      <div className="transaction-amount">
                        <p style={{ fontSize: '10px' }} className={transaction.type === "Credit" ? "Credit" : "Debit"}>
                          <>
                            Battle Amount:
                            <img
                              src={require(`../assets/rcoin.png`)}
                              width="20px"
                              height="20px"
                              alt="Winning Amount"
                              style={{ marginRight: '4px' }}
                            />
                            {`${transaction.amount}`}
                          </>
                        </p>
                        <p style={{ fontSize: '12px' }}>{formatDate(transaction.date)}</p>
                        <p style={{ fontSize: '12px' }}>{transaction.room_id}</p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}

          {transactionType === 'Referral' && (
            <div className="transactions">
              {currentTransactions.length === 0 ? (
                <div className="no-transactions">
                  <img src={require(`../assets/not-found.gif`)} width="120px" height="120px" alt="No Transactions" />
                  <h3>No Transactions Available</h3>
                  <p>You have no Referral History yet.</p>
                </div>
              ) : (
                currentTransactions.map((transaction, index) => (
                  <div className="transaction" key={index}>
                    <div className="transaction-details">
                      <div className="transaction-date">
                        <p style={{ fontSize: '14px' }}>Desc: {transaction.desc}</p>
                        <p style={{ fontSize: '12px' }}>Mode: {transaction.mode}</p>
                      </div>
                      <div className="transaction-amount">
                        <p className={transaction.type === "Credit" ? "Credit" : "Debit"}>
                          {transaction.type === "Credit" ? '(+)' : '(-)'}
                          <>
                            <img
                              src={require(`../assets/rcoin.png`)}
                              width="24px"
                              height="24px"
                              alt="Winning Amount"
                              style={{ marginRight: '4px' }}
                            />
                            {`${transaction.amount}`}
                          </>
                        </p>
                        <p>{formatDate(transaction.date)}</p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}

          {/* Pagination */}
          {transactions.length > transactionsPerPage && (
            <div className="pagination">
              {Array.from({ length: Math.ceil(transactions.length / transactionsPerPage) }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={index + 1 === currentPage ? 'active' : ''}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TransactionHistory;
