// src/components/Games.js
import React from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../styles/Game.css'; // Ensure your CSS file is correctly set up

const games = [
  {
    title: "Ludo Classic",
    image: "../assets/images/ludoclass.jpg",
    status: "LIVE",
    link: "/ludoclassicmanual",
  },
  {
    title: "Ludo Popular",
    image: "../assets/images/ludopopul.jpg",
    status: "Live",
    link: "",
  },
  {
    title: "Poker",
    image: "../assets/images/poker.jpg",
    status: "COMING SOON",
    link: "",
  },
  {
    title: "Aviator",
    image: "../assets/images/Aviat.jpg",
    status: "COMING SOON",
    link: "",
  },
  {
    title: "Colour Prediction",
    image: "../assets/images/colourPredict.png",
    status: "LIVE",
    link: "/colourgame",
  },
  {
    title: "Cricket",
    image: "../assets/images/cricket.jpg",
    status: "COMING SOON",
    link: "",
  },
];

const Games = () => {
  const navigate = useNavigate();
  const handleButtonClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {games.map((game, index) => (
          <Grid
            item
            xs={index < 2 ? 12 : 6}  // Full width for the first two boxes, 4 for the rest to fit 3 in a row
            key={index}
          >
            <Card
              onClick={() => handleButtonClick(game.link)}
              className='gamecardboxd'
              style={{
                boxShadow: '9px 9px 20px 2px rgba(0, 0, 0, 0.2)',
                cursor: game.link ? 'pointer' : 'default',
                position: 'relative'  // Allows positioning of the status label
              }}
            >
              <CardMedia
                component="img"
                alt={game.title}
                cover="fill"
                image={game.image}
                title={game.title}
              />
              {/* Status Label */}
              <Box
                className={`game-status ${game.status === 'LIVE' ? 'live' : 'coming-soon'}`}
                sx={{
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  padding: '5px 10px',
                  backgroundColor: game.status === 'LIVE' ? 'green' : 'gray',
                  color: '#FFF',
                  borderRadius: '5px',
                  animation: 'blink 3s infinite'  // Add blink animation for LIVE status
                }}
              >
                {game.status}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Games;
